import { LoginModalMixin } from './loginModal';
import {checkAgent} from '../../../helper-functions/helper-functions';
import { getItemSessionStorage, removeKeySessionStorage } from '../../../helper-functions/services';
import Upsell from '../mixins/upsell-policy';


//Applies to step 2 on all applications.
export default {
    data() {
        return {
            currentLang: window.globalLanguage.lang,
            shippingCharges: 0,
            isLoggedIn: ibJS?.affiliateValues?.myAccountLogin || false
        }
    },
    mixins: [
        LoginModalMixin,
        Upsell
    ],
    mounted() {
        checkAgent.call(this, [true]);
    },
    methods: {
        childDisplayCount(i) {
            const x = this.application.travelerInfos;

            return x[1] && x[1].travelerType === 'spouse' && x.length > 3 ? i - 1
                 : x[1] && x[1].travelerType !== 'spouse' && x.length > 2 ? i
                 : '';
        },
        showLoginModal() {
            this.requestLoginModal();
            sessionStorage.setItem("isShowLogin", true);
        }
    },
    computed: {
        planResultQuoteDuration() {
            return this.application?.planResult?.quotedDuration || null;
        }
    },
    watch: {
        plan() {
            try {
                // Logic for all Family type applications IMG, ARS, GU, Visitors Secure
                // When the new QF passes multiple travelers including second adult then the App object will be empty
                let qrFrmData = getItemSessionStorage('plan-criteria-stp2');
                if (!this.form.policyMaximum && !this.form.deductible && qrFrmData && this.plan.deductibles && this.plan.policyMaximums) {
                    qrFrmData = JSON.parse(qrFrmData);
                    let hasPolicyMax = null;
                    let hasDeductible = null;
                    if (this.form.hasOwnProperty('policyMaximum') && qrFrmData.policyMaximum) {
                        hasPolicyMax = this.plan.policyMaximums.find(pmax => +pmax === +qrFrmData.policyMaximum);
                        if (hasPolicyMax) this.form.policyMaximum = qrFrmData.policyMaximum;
                    }
                    if (this.form.hasOwnProperty('deductible') && qrFrmData.deductible) {
                        hasDeductible = this.plan.deductibles.find(dts => +dts === +qrFrmData.deductible);
                        if (hasDeductible) this.form.deductible = qrFrmData.deductible;
                    }
                    if (hasDeductible != null && hasPolicyMax != null) this.calculatePrices();
                    
                    removeKeySessionStorage('plan-criteria-stp2');
                }
            } catch (error) {
                console.log("Error in prefilling QF to App", error);
            }
        }
    }
};